import { forwardRef } from 'react';

import BaseInput, { BaseInputProps } from '../BaseInput';

export type EmailInputProps = BaseInputProps;

/**
 * NOTE: The type is purposefully "text" here vs the allowed HTMLInputTypeAttribute
 * types. If we pass type="email" the browsers validation takes over if the input
 * is incorrect. We use Zod for validation and display error messages accordingly.
 * The browsers validation message adds confusion and obscures the error
 * message under the input.
 */

const EmailInput = forwardRef<HTMLInputElement, BaseInputProps>((props, ref) => {
    return (
        <BaseInput
            {...props}
            autoCapitalize="none"
            autoCorrect="off"
            inputMode="email"
            ref={ref}
            type="text"
        />
    );
});

export default EmailInput;
