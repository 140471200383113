import classNames from 'classnames/bind';
import type { ChangeEventHandler, FocusEventHandler, ReactNode } from 'react';
import { forwardRef } from 'react';

import InputGroup, { InputGroupProps } from '../InputGroup';
import Radio from '../Radio';
import classes from './RadioGroup.module.scss';

const cx = classNames.bind(classes);

export interface RadioOption {
    label: ReactNode;
    value: string;
}

export interface CommonProps extends Omit<InputGroupProps, 'children' | 'className'> {
    /** Is the radio group in a disabled state */
    disabled?: boolean;
    /** onBlur event handler */
    onBlur?: FocusEventHandler<HTMLInputElement>;
    /** Selectable options */
    options: RadioOption[];
    /** Current value that should display as selected */
    selected?: string;
    /** What direction are the radio button layed out */
    stack?: 'vert' | 'horiz';
}

export interface ControlledRadioGroupProps extends CommonProps {
    /** If the RadioGroup is a controlled input - You specify the checked value */
    controllable: true;
    /** onChange event handler */
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export interface UnControlledRadioGroupProps extends CommonProps {
    controllable?: false;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export type RadioGroupProps = ControlledRadioGroupProps | UnControlledRadioGroupProps;

const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
    (
        {
            caption,
            controllable,
            disabled,
            errors,
            hideLabel,
            label,
            name,
            onBlur,
            onChange,
            options,
            required,
            secondaryLabel,
            selected,
            stack = 'vert',
        },
        ref
    ) => {
        return (
            <InputGroup
                caption={caption}
                errors={errors}
                hideLabel={hideLabel}
                label={label}
                name={name}
                required={required}
                secondaryLabel={secondaryLabel}
            >
                {({ errorId, isInvalid, labelId }) => (
                    <div
                        aria-labelledby={labelId}
                        className={cx(classes.group, {
                            'group--vert': stack === 'vert',
                            'group--horiz': stack === 'horiz',
                            'group--invalid': isInvalid,
                        })}
                        role="radiogroup"
                    >
                        {options.map((option) => {
                            const { label, value: radioValue } = option;

                            if (controllable) {
                                return (
                                    <Radio
                                        aria-describedby={errorId}
                                        checked={radioValue === selected}
                                        controllable
                                        disabled={disabled}
                                        key={radioValue}
                                        label={label}
                                        name={name}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        ref={ref}
                                        required={required}
                                        value={radioValue}
                                    />
                                );
                            }

                            return (
                                <Radio
                                    aria-describedby={errorId}
                                    defaultChecked={radioValue === selected}
                                    disabled={disabled}
                                    key={radioValue}
                                    label={label}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    ref={ref}
                                    required={required}
                                    value={radioValue}
                                />
                            );
                        })}
                    </div>
                )}
            </InputGroup>
        );
    }
);

export default RadioGroup;
