import classNames from 'classnames/bind';
import type { AriaAttributes, ChangeEventHandler, FocusEventHandler, ReactNode } from 'react';
import { forwardRef } from 'react';

import classes from './Checkbox.module.scss';

const cx = classNames.bind(classes);

export type CheckboxSize = 'sm' | 'lg';

interface CommonProps extends AriaAttributes {
    /** A valid ReactNode */
    children?: ReactNode;
    /** Add additional css class names */
    className?: string;
    /** Is the checkbox in a disabled state */
    disabled?: boolean;
    /** Hide label visually. Still available to screen readers */
    hideLabel?: boolean;
    /* Label of the checkbox */
    label?: ReactNode;
    /* Name of the checkbox */
    name: string;
    /** Function to be called on the onBlur event */
    onBlur?: FocusEventHandler<HTMLInputElement>;
    /** Is the checkbox required */
    required?: boolean;
    /** Size of the input box */
    size?: CheckboxSize;
    /** Value of the checkbox */
    value: string;
}

export interface ControlledCheckboxProps extends CommonProps {
    /** Is the checkbox checked */
    checked: boolean;
    /** If the checkbox is a controlled input - You specify if it's checked */
    controllable: true;
    defaultChecked?: never;
    /** Function to be called on the onChange event */
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export interface UnControlledCheckboxProps extends CommonProps {
    checked?: never;
    controllable?: false;
    /** Is the checkbox's initial state checked - UnControlled */
    defaultChecked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export type CheckboxProps = ControlledCheckboxProps | UnControlledCheckboxProps;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            checked,
            children,
            className,
            controllable = false,
            defaultChecked,
            disabled = false,
            hideLabel,
            label,
            name,
            onBlur,
            onChange,
            required,
            size = 'sm',
            value,
            ...rest
        },
        ref
    ) => {
        return (
            <label
                className={cx(
                    'checkbox',
                    `checkbox--${size}`,
                    {
                        'checkbox--disabled': disabled,
                    },
                    className
                )}
                data-testid="input-label"
            >
                <input
                    checked={controllable ? checked : undefined}
                    defaultChecked={!controllable ? defaultChecked : undefined}
                    disabled={disabled}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    required={required}
                    type="checkbox"
                    value={value}
                    {...rest}
                />
                <span
                    className={cx('checkbox__label', {
                        'sr-only': hideLabel,
                    })}
                >
                    {label}
                    {children}
                </span>
            </label>
        );
    }
);

export default Checkbox;
