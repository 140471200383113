import classNames from 'classnames/bind';
import type { ChangeEventHandler, FocusEventHandler, ReactNode } from 'react';
import { forwardRef } from 'react';

import Checkbox, { CheckboxSize } from '../Checkbox';
import InputGroup, { InputGroupProps } from '../InputGroup';
import classes from './CheckboxGroup.module.scss';

const cx = classNames.bind(classes);

export interface CheckboxOption {
    label: ReactNode;
    value: string;
}

export type StackDirection = 'vert' | 'horiz';

export interface CommonProps extends Omit<InputGroupProps, 'children' | 'className'> {
    /** Are the checkboxes disabled */
    disabled?: boolean;
    /** Function to be called on the onBlur event */
    onBlur?: FocusEventHandler<HTMLInputElement>;
    /** Selectable options */
    options: CheckboxOption[];
    /** An array of selected values */
    selected?: string[];
    /** Size of the input boxes */
    size?: CheckboxSize;
    /** What direction are the checkboxes layed out */
    stack?: StackDirection;
}

export interface ControlledCheckboxGroupProps extends CommonProps {
    /** If the CheckboxGroup is a controlled input - You specify the checked value */
    controllable: true;
    /** Function to be called on the onChange event */
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export interface UnControlledCheckboxGroupProps extends CommonProps {
    controllable?: false;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export type CheckboxGroupProps = ControlledCheckboxGroupProps | UnControlledCheckboxGroupProps;

const CheckboxGroup = forwardRef<HTMLInputElement, CheckboxGroupProps>(
    (
        {
            caption,
            errors,
            controllable,
            hideLabel,
            label,
            name,
            options,
            required,
            secondaryLabel,
            selected = [],
            disabled,
            onBlur,
            onChange,
            size,
            stack = 'vert',
        },
        ref
    ) => {
        return (
            <InputGroup
                caption={caption}
                errors={errors}
                hideLabel={hideLabel}
                label={label}
                name={name}
                required={required}
                secondaryLabel={secondaryLabel}
            >
                {({ errorId, isInvalid, labelId }) => (
                    <div
                        aria-labelledby={labelId}
                        className={cx(classes.group, {
                            'group--vert': stack === 'vert',
                            'group--horiz': stack === 'horiz',
                            'group--invalid': isInvalid,
                        })}
                        role="group"
                    >
                        {options.map((option) => {
                            const { label, value } = option;

                            if (controllable) {
                                return (
                                    <Checkbox
                                        aria-describedby={errorId}
                                        checked={selected.includes(value)}
                                        controllable
                                        disabled={disabled}
                                        key={value}
                                        label={label}
                                        name={name}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        ref={ref}
                                        required={required}
                                        size={size}
                                        value={value}
                                    />
                                );
                            }

                            return (
                                <Checkbox
                                    aria-describedby={errorId}
                                    defaultChecked={selected.includes(value)}
                                    disabled={disabled}
                                    key={value}
                                    label={label}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    ref={ref}
                                    required={required}
                                    size={size}
                                    value={value}
                                />
                            );
                        })}
                    </div>
                )}
            </InputGroup>
        );
    }
);

export default CheckboxGroup;
